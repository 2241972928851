import React, { useEffect } from "react";
import { useLoading } from "../store/loader";

const QRCodePage: React.FC = () => {
  const { setLoading } = useLoading();
  useEffect(() => {
    setLoading(true)
  })
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="relative">
        <img
          className="max-h-136"
          src="../qrcode.png"
          alt="logo"
        />
      </div>
    </div>
  );
};

export default QRCodePage;
