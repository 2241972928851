import {
  InteractionType,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import React, { FunctionComponent, ReactNode } from "react";

import { loginRequest, msalConfig } from "./msalConfig";

interface IAzureADWithAuthCondition {
  children: ReactNode;
}

export const msalAuthProvider = new PublicClientApplication(msalConfig);

const request: RedirectRequest = {
  scopes: loginRequest.scopes,
  redirectUri: `${window.location.origin}/admin`
};
const AzureAuthProvider: FunctionComponent<IAzureADWithAuthCondition> = (
  props
) => {
  return (
    <MsalProvider instance={msalAuthProvider}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={request}
      >
        {props.children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default AzureAuthProvider;
