import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { useLoading } from "../store/loader";

function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (error) {
      navigate("/unavailable");
      setLoading(true);
    }
  }, [error, navigate]);

  return null;
}

export default ErrorBoundary;
