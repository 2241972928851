import React, { useState } from "react";
import Table from "./TablePrize";
import AddPrize from "./AddPrize";
import EditPrize from "./EditPrize";
import { useLoaderData } from "react-router-dom";
import { prizeService } from "../../service";
import {
  AddPrizeModel,
  EditPrizeModel,
  PrizeModel,
} from "../../models/PrizeModel";
import { useLoading } from "../../store/loader";

export async function allPrizeLoader() {
  try {
    const Prizes: PrizeModel[] = await prizeService.getAllPrize();
    return { Prizes };
  } catch (err) {
    console.log("err", err);
  }
}

interface AllPrize {
  Prizes: PrizeModel[];
}

const LuckyDrawPage: React.FC = () => {
  const { Prizes } = useLoaderData() as AllPrize;
  const [data, setData] = useState(Prizes);
  const { setLoading } = useLoading();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState<PrizeModel>({
    id: 0,
    name: "",
    description: "",
    quantity: 1,
    onStage: false,
    drawable: true,
    winners: [],
    showWinner: false,
  });

  const handleAddPrize = async (newPrize: AddPrizeModel) => {
    try {
      setLoading(false);
      await prizeService.addPrize(newPrize);
      const newPrizeList = await prizeService.getAllPrize();
      setData(newPrizeList);
    } catch (err) {
      console.log("err", err);
      throw err;
    } finally {
      setLoading(true);
    }
  };

  const handleEditPrize = async (id: number, updatedPrize: EditPrizeModel) => {
    try {
      setLoading(false);
      await prizeService.editPrize(id, updatedPrize);
      const newPrizeList = await prizeService.getAllPrize();
      setData(newPrizeList);
    } catch (err) {
      console.log("err", err);
      throw err;
    } finally {
      setLoading(true);
      setIsEditModalOpen(false);
    }
  };

  const handleDeletePrize = async (id: number) => {
    try {
      setLoading(false);
      await prizeService.delPrize(id);
      const newPrizeList = await prizeService.getAllPrize();
      setData(newPrizeList);
    } catch (err) {
      console.log("err", err);
      throw err;
    } finally {
      setLoading(true);
    }
  };

  const handleDrawPrize = async (id: number) => {
    try {
      setLoading(false);
      await prizeService.drawPrize(id);
      const newPrizeList = await prizeService.getAllPrize();
      setData(newPrizeList);
    } catch (err) {
      console.log("err", err);
      throw err;
    } finally {
      setLoading(true);
    }
  };

  const handleSetShowWinner = async (id: number) => {
    try {
      setLoading(false);
      await prizeService.setShowPrize(id);
      const newPrizeList = await prizeService.getAllPrize();
      setData(newPrizeList);
    } catch (err) {
      console.log("err", err);
      throw err;
    } finally {
      setLoading(true);
    }
  };

  const handleEditClick = (prize: PrizeModel) => {
    setSelectedPrize(prize);
    setIsEditModalOpen(true);
  };

  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="w-full max-w-6xl bg-white shadow-lg rounded-lg p-8 space-y-8">
        <h1 className="text-3xl text-center text-gray-800">Lucky Draw</h1>
        <div className="overflow-x-auto">
          <Table
            data={data}
            onDraw={handleDrawPrize}
            onEdit={handleEditClick}
            onDelete={handleDeletePrize}
            onSetShowWinner={handleSetShowWinner}
          />
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="bg-customRed-400 text-white px-3 py-2 rounded hover:bg-customRed-600 transition duration-300"
          >
            Add
          </button>
        </div>
      </div>
      <AddPrize
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSave={handleAddPrize}
      />
      <EditPrize
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        prize={selectedPrize}
        onSave={handleEditPrize}
      />
    </div>
  );
};

export default LuckyDrawPage;
