import { AttendeeModel, CreateEmployeeDto, EmployeeModel } from "../../models/EmployeeModel";
import axios from "../axios";

interface RegisterInterface {
  badgeId?: string,
  email?: string,
}

export const register = async (request: RegisterInterface) => {
  const endpoint = "/v1/register";

  return axios
    .post<EmployeeModel>(endpoint, request)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const createAttendee = async (request: CreateEmployeeDto) => {
  const endpoint = "/v1/attendee";

  return axios
    .post<EmployeeModel>(endpoint, request)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const searchAttendee = (fullname: string) => {
  const endpoint = `/v1/attendee?fullname=${fullname}`;

  return axios
    .get<AttendeeModel[]>(endpoint)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAttendee = (email: string) => {
  const endpoint = `/v1/attendee/${email}`;

  return axios
    .get<EmployeeModel>(endpoint)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getRegisterCount = () => {
  const endpoint = `/v1/register`;

  return axios
    .get<number>(endpoint)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

const exportService = {
  register,
  searchAttendee,
  getAttendee,
  getRegisterCount,
  createAttendee
};

export default exportService;
