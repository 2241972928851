import React, { useEffect, useState } from "react";
import { useLoading } from "../store/loader";

interface Winner {
  firstname: string;
  lastname: string;
  prizeName: string;
}

const WinnerView: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [originalData, setOriginalData] = useState<Winner[]>([]);
  const [filteredData, setFilteredData] = useState<Winner[]>([]);
  const { setLoading } = useLoading();
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(false);
      try {
        const response = await fetch(
          "https://event-registration.azurewebsites.net/v1/winners"
        );
        const data = await response.json();
        setOriginalData(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(true);
      }
    };
    fetchData();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = originalData.filter(
      ({ firstname, lastname, prizeName }) => {
        const fullName = `${firstname.toLowerCase()} ${lastname.toLowerCase()}`;
        return (
          fullName.includes(term) || prizeName.toLowerCase().includes(term)
        );
      }
    );
    setFilteredData(filtered);
  };
  const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password === "em2024") {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  if (!isAuthenticated) {
    return (
      <>
        <form
          onSubmit={handlePasswordSubmit}
          className="bg-white p-6 rounded-lg shadow-md"
        >
          <h2 className="text-2xl font-bold mb-4 text-center">
            Enter event code
          </h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mb-4 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-customRed-500 focus:border-customRed-500"
            placeholder="event code"
          />
          <button
            type="submit"
            className="w-full bg-customRed-400 text-white p-3 rounded-md shadow-sm hover:bg-customRed-600"
          >
            Join
          </button>
        </form>
      </>
    );
  }

  return (
    <div className="w-full max-w-4xl shadow-lg rounded-lg p-8 space-y-8">
      <div className="flex items-center justify-center">
        <img className="max-w-lg" src="./em-logo.png" alt="logo" />
      </div>
      <input
        type="text"
        placeholder="Enter your name"
        value={searchTerm}
        onChange={handleSearch}
        className="mb-6 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-customRed-500 focus:border-customRed-500"
      />
      <div className="w-full overflow-x-auto">
        {filteredData.length > 0 ? (
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-customRed-500 text-white text-center text-sm font-semibold">
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300">Name</th>
                <th className="px-6 py-3 border-b-2 border-gray-300">Prize</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map(({ firstname, lastname, prizeName }, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-100 border-gray-300 text-center"
                >
                  <td className="px-6 py-4 border-b w-2/3">
                    {firstname} {lastname}
                  </td>
                  <td className="px-6 py-4 border-b w-1/3">{prizeName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-gray-500 text-center">No winners found</div>
        )}
      </div>
    </div>
  );
};

export default WinnerView;
