import { AuthenticationResult, EventType } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
import { msalAuthProvider } from '../../auth/AzureAuth';
import { loginRequest } from '../../auth/msalConfig';

const BACKEND_BASEURL = 'https://event-registration.azurewebsites.net';

const initializeMsalAuth = () => {
  const msalAuth = msalAuthProvider;
  const accounts = msalAuth.getAllAccounts();

  if (accounts.length > 0) {
    msalAuth.setActiveAccount(accounts[0]);
  }

  msalAuth.addEventCallback(({ eventType, payload }) => {
    if (eventType === EventType.LOGIN_SUCCESS && payload) {
      const { account } = payload as AuthenticationResult;
      msalAuth.setActiveAccount(account);
    }
  });

  return msalAuth;
};

const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: BACKEND_BASEURL,
    validateStatus(status) {
      return status >= 200 && status < 300;
    },
  });

  const msalAuth = initializeMsalAuth();
  const accounts = msalAuth.getAllAccounts();
  const request = {
    account: accounts[0],
    ...loginRequest,
  };

  instance.interceptors.request.use(async (config) => {
    await msalAuth.initialize();
    let token;

    try {
      const response = await msalAuth.acquireTokenSilent(request);
      token = response.accessToken;
    } catch (error) {
      console.error(
        'Silent token acquisition failed, acquiring token via redirect',
        error
      );
      await msalAuth.acquireTokenRedirect(loginRequest);
    }

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        try {
          const response = await msalAuth.acquireTokenSilent(request);
          const config = error.config;
          config.headers.authorization = `Bearer ${response.accessToken}`;
          return axios.request(config);
        } catch (tokenRefreshError) {
          console.error('Token refresh failed', tokenRefreshError);
          return Promise.reject(tokenRefreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const instance = createAxiosInstance();

export default instance;
