import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserRole } from "../contexts/UserRoleContext";

interface RoleBasedRouteProps {
  roles: string[];
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({ roles }) => {
  const { userRoles, loading } = useUserRole();

  if (loading) {
    return <div>Loading...</div>;
  }

  const hasRequiredRole = roles.some((role) => userRoles.includes(role));

  return hasRequiredRole ? <Outlet /> : <Navigate to="/unavailable" replace />;
};

export default RoleBasedRoute;
