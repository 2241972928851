import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { registryService } from "../service";
import EmployeeCard from "../components/EmployeeCard";
import { useLoading } from "../store/loader";
import { AttendeeModel, EmployeeModel } from "../models/EmployeeModel";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";

const AttendanceSearchingPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeModel | null>(null);
  const [employeeList, setEmployeeList] = useState<AttendeeModel[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [employeeNotFound, setEmployeeNotFound] = useState(false);
  const { setLoading } = useLoading();

  const getEmployee = async (email: string) => {
    try {
      setLoading(false);
      const profile = await registryService.getAttendee(email);
      setSelectedEmployee(profile);
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.status === 404) {
          setEmployeeNotFound(true);
        }
      } else {
        console.error(err);
      }
    } finally {
      setLoading(true);
    }
  };

  const handleSelectAttendee = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const email = event.currentTarget.getAttribute("data-email") || "";
    setEmployeeList([]);
    setSearchTerm("");
    setShowDropDown(false);
    setEmployeeNotFound(false);
    setSelectedEmployee(null);
    await getEmployee(email);
  };

  const handleSearchName = (fullname: string) => {
    try {
      setShowDropDown(true);
      setLoadingSearch(true);
      registryService.searchAttendee(fullname).then((employeeList) => {
        setEmployeeList(employeeList);
        setLoadingSearch(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchEmployeeName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (searchTerm) {
        handleSearchName(event.currentTarget.value);
      } else {
        setEmployeeList([]);
        setShowDropDown(false);
      }
    } else if (event.key === "Escape") {
      setEmployeeList([]);
      setShowDropDown(false);
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-screen max-w-lg bg-white shadow-lg rounded-lg p-8 space-y-4 backdrop-filter backdrop-blur-lg">
        <h1 className="text-4xl text-gray-900 mb-6 text-center">
          Attendance Management
        </h1>
        <div>
          <label
            htmlFor="employee-name"
            className="block text-xl font-medium text-gray-700"
          >
            Employee Name:
          </label>
          <div className="relative">
            <div className="mt-2 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                id="employee-name"
                type="text"
                className="block w-full pl-12 px-4 py-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg"
                placeholder="Search by Name"
                value={searchTerm}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchTerm(value);
                  if (!value) {
                    setEmployeeList([]);
                    setShowDropDown(false);
                  }
                }}
                onKeyDown={handleSearchEmployeeName}
              />
            </div>
            {showDropDown && (
              <div className="mt-2 bg-white shadow-md rounded-md z-10 absolute w-full overflow-auto max-h-64">
                {loadingSearch ? (
                  <div className="flex justify-center items-center p-3">
                    <div className="w-6 h-6 border-4 border-customRed-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                  </div>
                ) : employeeList.length ? (
                  employeeList.map(({ firstname, lastname, email }) => (
                    <div
                      key={`${firstname} ${lastname}`}
                      className="flex justify-between items-center p-3 hover:bg-gray-100"
                    >
                      <span>{`${firstname} ${lastname}`}</span>
                      <button
                        data-email={email}
                        onClick={handleSelectAttendee}
                        className="ml-4 bg-customRed-500 text-white px-3 py-1 rounded-md"
                      >
                        View
                      </button>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center items-center p-3 text-red-500 mt-2">
                    Employee Not Found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {employeeNotFound && (
          <div className="flex justify-center">
            User has not pre-register or register as walk-in yet
          </div>
        )}
        {selectedEmployee && <EmployeeCard employee={selectedEmployee} />}
        <div className="flex items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 text-gray-400">Or</span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div>
          <Link to="/admin/attendance-management/create">
            <button className="bg-customRed-400 text-white border-10 rounded-md py-3 w-full hover:bg-customRed-600 transition duration-300">
              Create New Employee
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AttendanceSearchingPage;
