import {
  AddPrizeModel,
  EditPrizeModel,
} from "../../models/PrizeModel";
import axios from "../axios";

const getAllPrize = async () => {
  const endpoint = "/v1/prize";

  return axios
    .get(endpoint)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

const getPrize = async (id: number) => {
  const endpoint = `/v1/prize/${id}`;

  return axios
    .get(endpoint)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

const addPrize = async (data: AddPrizeModel) => {
  const endpoint = `/v1/prize`;

  return axios
    .post(endpoint, data)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
const editPrize = async (id: number, data: EditPrizeModel) => {
  const endpoint = `/v1/prize/${id}`;

  return axios
    .put(endpoint, data)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

const delPrize = async (id: number) => {
  const endpoint = `/v1/prize/${id}`;

  return axios
    .delete(endpoint)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

const drawPrize = async (id: number) => {
  const endpoint = `/v1/lucky-draw`;
  const data = {
    prizeId: id,
  };

  return axios
    .post(endpoint, data)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

const reDrawPrize = async (id: number, email: string) => {
  const endpoint = `/v1/lucky-re-draw`;
  const data = {
    prizeId: id,
    email: email,
  };

  return axios
    .post(endpoint, data)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

const setShowPrize = async (prizeId: number) => {
  const endpoint = `/v1/prize/show-winners/${prizeId}`;

  return axios
    .post<void>(endpoint)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

const exportService = {
  getAllPrize,
  getPrize,
  addPrize,
  editPrize,
  delPrize,
  drawPrize,
  reDrawPrize,
  setShowPrize
};

export default exportService;
