import React, { useState } from "react";
import useValidateForm from "../../../hooks/useValidateForm";
import { AddPrizeModel } from "../../../models/PrizeModel";

interface InputDetailField {
  value: string;
  errorMessage: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: AddPrizeModel) => void;
}

const AddPrize: React.FC<ModalProps> = ({ isOpen, onClose, onSave }) => {
  const initialFormData = {
    value: "",
    errorMessage: "",
  };

  const [prizeNameInput, setPrizeNameInput] =
    useState<InputDetailField>(initialFormData);
  const [descriptionInput, setDescriptionInput] = useState<string>("");
  const [quantityInput, setQuantityInput] =
    useState<InputDetailField>(initialFormData);
  const [onStageCheck, setOnStageCheck] = useState(false);
  const { validatePrize, validateQuantity } = useValidateForm();

  const handlePrizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const errorMessage = validatePrize(value);

    setPrizeNameInput({
      value: value.charAt(0).toUpperCase() + value.slice(1),
      errorMessage,
    });
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const errorMessage = validateQuantity(value);
    setQuantityInput({
      value,
      errorMessage,
    });
  };

  const handleSubmit = () => {
    if (!isFormInvalid()) {
      const result = {
        name: prizeNameInput.value,
        description: descriptionInput,
        quantity: parseInt(quantityInput.value),
        onStage: onStageCheck,
      };
      onSave(result);
      resetFrom()
      onClose();
    } else {
      setPrizeNameInput({
        value: prizeNameInput.value,
        errorMessage: validatePrize(prizeNameInput.value.toString()),
      });
      setQuantityInput({
        value: quantityInput.value,
        errorMessage: validateQuantity(quantityInput.value),
      });
    }
  };

  const isFormInvalid = () => {
    return !!(
      !prizeNameInput.value ||
      !quantityInput.value ||
      prizeNameInput.errorMessage ||
      quantityInput.errorMessage
    );
  };

  const resetFrom = () => {
    setPrizeNameInput(initialFormData);
    setQuantityInput(initialFormData);
    setDescriptionInput('')
    setOnStageCheck(false)
  }

  const handleClose = () => {
    resetFrom()
    onClose()
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-xl bg-white shadow-lg rounded-lg p-8 relative">
        <button
          onClick={handleClose}
          className="absolute top-2 right-4 text-gray-500 hover:text-gray-700 text-3xl"
        >
          &times;
        </button>
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Add Prize
        </h1>
        <div className="mb-3">
          <label
            htmlFor="prize"
            className="block text-sm font-normal text-gray-700 mr-2"
          >
            <abbr className="text-red-500 mr-1">*</abbr>Prize:
          </label>
          <input
            id="prize"
            type="text"
            className={`px-3 py-1 w-full border ${
              prizeNameInput.errorMessage
                ? "border-red-300 border-2"
                : "border-gray-300"
            } rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
            placeholder="Voucher 100 Baht"
            required
            value={prizeNameInput.value}
            onChange={handlePrizeChange}
          />
          {prizeNameInput.errorMessage && (
            <label className="block text-xs font-normal text-red-400">
              {prizeNameInput.errorMessage}
            </label>
          )}
        </div>
        <div className="mb-3">
          <label
            htmlFor="quantity"
            className="block text-sm font-normal text-gray-700 mr-2"
          >
            <abbr className="text-red-500 mr-1">*</abbr>Quantity:
          </label>
          <input
            id="quantity"
            type="text"
            className={`px-3 py-1 w-full border ${
              quantityInput.errorMessage
                ? "border-red-300 border-2"
                : "border-gray-300"
            } rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
            placeholder="Quantity"
            required
            value={quantityInput.value}
            onChange={handleQuantityChange}
          />
          {quantityInput.errorMessage && (
            <label className="block text-xs font-normal text-red-400">
              {quantityInput.errorMessage}
            </label>
          )}
        </div>

        <label
          htmlFor="description"
          className="block text-sm font-normal text-gray-700 mr-2"
        >
          Description
        </label>
        <input
          id="description"
          type="text"
          className={`px-3 py-1 mb-3 w-full border border-gray-300 rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
          placeholder="Draw after first stage"
          required
          value={descriptionInput}
          onChange={(event) => setDescriptionInput(event.target.value)}
        />
        <div className="mb-6 flex items-center">
          <label
            htmlFor="on-stage"
            className="text-base font-normal text-gray-700 mr-2"
          >
            On Stage:
          </label>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              name="onStage"
              checked={onStageCheck}
              onChange={(event) => setOnStageCheck(event.target.checked)}
              className="sr-only peer"
            />
            <span className="relative w-14 h-7 bg-gray-200 rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-400" />
          </label>
        </div>
        <button
          type="submit"
          className={`${
            isFormInvalid()
              ? "bg-neutral-300 hover:bg-neutral-200"
              : "bg-blue-500 hover:bg-blue-600"
          }  text-white w-full py-2 rounded-md`}
          onClick={() => {
            handleSubmit();
          }}
          disabled={isFormInvalid()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddPrize;
