import {
  faEdit,
  faEye,
  faTrashCan,
  faFileCsv,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrizeModel } from "../../../models/PrizeModel";
import ConfirmModal from "../../../components/ConfirmModal";

interface TableProps {
  data: PrizeModel[];
  onDraw: (id: number) => Promise<void>;
  onEdit: (prize: PrizeModel) => void;
  onDelete: (id: number) => Promise<void>;
  onSetShowWinner: (id: number) => Promise<void>;
}

const Table: React.FC<TableProps> = ({ data, onDraw, onEdit, onDelete, onSetShowWinner }) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmDrawModal, setShowConfirmDrawModal] = useState(false);
  const [confirmShowWinnerModal, setConfirmShowWinnerModal] = useState(false);

  const [prizeId, setPrizeId] = useState<number>();
  const navigate = useNavigate();

  const handleViewClick = (id: number) => {
    navigate(`/admin/lucky-draw/winners/${id}`, { state: { data } });
  };

  const handleDrawClick = (id: number) => {
    setShowConfirmDrawModal(true);
    setPrizeId(id);
  };

  const handleDownloadCSV = (id: number) => {
    const prize = data.find((prize) => prize.id === id);

    // Generate code with power of Github Copilot
    if (prize && prize.winners.length > 0) {
      const csvData = prize.winners
        .map((winner) => {
          return `${winner.firstname} ${winner.lastname},${winner.email}`;
        })
        .join("\n");

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${prize.name}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDeleteClick = (id: number) => {
    setShowConfirmDeleteModal(true);
    setPrizeId(id);
  };

  const handleShowPrize = (id:number) => {
    setConfirmShowWinnerModal(true);
    setPrizeId(id);
  }

  return (
    <div className="max-h-60vh overflow-auto overflow-x-auto">
      <table className="min-w-full bg-white border-collapse">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200 text-center">
              No
            </th>
            <th className="py-2 px-4 border-b border-gray-200 text-center">
              Prize
            </th>
            <th className="py-2 px-4 border-b border-gray-200 text-center">
              Quantity
            </th>
            <th className="py-2 px-4 border-b border-gray-200 text-center">
              Description
            </th>
            <th className="py-2 px-4 border-b border-gray-200 text-center">
              Onstage
            </th>
            <th className="py-2 px-4 border-b border-gray-200 text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b border-gray-200 text-center">
                {index + 1}
              </td>
              <td className="py-2 px-4 border-b border-gray-200 text-center">
                {row.name}
              </td>
              <td className="py-2 px-4 border-b border-gray-200 text-center">
                {row.quantity}
              </td>
              <td className="py-2 px-4 border-b border-gray-200 text-center">
                {row.description}
              </td>
              <td className="py-2 px-4 border-b border-gray-200 text-center">
                {row.onStage ? "Yes" : "No"}
              </td>
              <td className="py-2 px-4 border-b border-gray-200 text-center">
                <div className="flex justify-center space-x-3">
                  <FontAwesomeIcon
                    className={`px-3 py-2 rounded ${
                      row.winners.length > 0
                        ? "bg-blue-400 text-white cursor-pointer"
                        : "bg-gray-400 text-gray-200 cursor-not-allowed"
                    }`}
                    onClick={() =>
                      row.winners.length > 0 && handleDownloadCSV(row.id)
                    }
                    icon={faFileCsv}
                    aria-label="View"
                  />
                  <FontAwesomeIcon
                    className={`px-3 py-2 rounded ${
                      row.winners.length > 0
                        ? "bg-blue-400 text-white cursor-pointer"
                        : "bg-gray-400 text-gray-200 cursor-not-allowed"
                    }`}
                    onClick={() =>
                      row.winners.length > 0 && handleViewClick(row.id)
                    }
                    icon={faEye}
                    aria-label="View"
                  />
                  <FontAwesomeIcon
                    className={`bg-blue-400 text-white px-3 py-2 rounded cursor-pointer ${
                      !row.drawable ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={() => row.drawable && onEdit(row)}
                    icon={faEdit}
                    aria-label="Edit"
                  />
                  <FontAwesomeIcon
                    className={`bg-red-400 text-white px-3 py-2 rounded cursor-pointer ${
                      !row.drawable ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={() => row.drawable && handleDeleteClick(row.id)}
                    icon={faTrashCan}
                    aria-label="Delete"
                  />
                  <FontAwesomeIcon
                    className={`bg-blue-400 text-white px-3 py-2 rounded cursor-pointer ${
                      (row.showWinner || row.drawable) ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={() => (!row.showWinner && !row.drawable) && handleShowPrize(row.id)}
                    icon={faTrophy}
                    aria-label="Show Prize"
                  />
                  {!row.drawable ? (
                    <button
                      className="bg-gray-400 text-white px-2 py-1 rounded w-20 h-8 cursor-not-allowed"
                      disabled
                    >
                      Draw
                    </button>
                  ) : row.onStage && row.winners.length > 0 ? (
                    <button
                      className="bg-blue-500 text-white px-2 py-1 rounded w-20 h-8"
                      onClick={() => handleViewClick(row.id)}
                    >
                      ReDraw
                    </button>
                  ) : (
                    <button
                      className="bg-blue-500 text-white px-2 py-1 rounded w-20 h-8"
                      onClick={() => handleDrawClick(row.id)}
                    >
                      Draw
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirmModal
        showModal={showConfirmDeleteModal}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onConfirm={() => {
          setShowConfirmDeleteModal(false);
          if (prizeId) {
            onDelete(prizeId);
          }
        }}
        variant="cancel"
        rejectMessage="No"
        confirmMessage="Yes"
      >
        <p>Are you sure you want to delete the prize?</p>
      </ConfirmModal>
      <ConfirmModal
        showModal={showConfirmDrawModal}
        onCancel={() => {
          setShowConfirmDrawModal(false);
        }}
        onConfirm={() => {
          setShowConfirmDrawModal(false);
          if (prizeId) {
            onDraw(prizeId);
          }
        }}
        variant="draw"
        rejectMessage="No"
        confirmMessage="Yes"
      >
        <p>Are you sure you want to draw the prize?</p>
      </ConfirmModal>
      <ConfirmModal
        showModal={confirmShowWinnerModal}
        onCancel={() => {
          setConfirmShowWinnerModal(false);
        }}
        onConfirm={() => {
          setConfirmShowWinnerModal(false);
          if (prizeId) {
            onSetShowWinner(prizeId);
          }
        }}
        variant="draw"
        rejectMessage="No"
        confirmMessage="Yes"
      >
        <p>Are you sure you want to set this prize to show winner?</p>
      </ConfirmModal>
    </div>
  );
};

export default Table;
