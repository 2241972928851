import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../components/ConfirmModal";
import useValidateForm from "../hooks/useValidateForm";
import EmployeeCard from "../components/EmployeeCard";
import { createAttendee, register } from "../service/registry";
import { useLoading } from "../store/loader";

interface InputDetailField {
  value: string;
  errorMessage: string;
}

const AttendanceManagementPage: React.FC = () => {
  const [badgeId, setBadgeId] = useState("");
  const [firstname, setFirstName] = useState<InputDetailField>({
    value: "",
    errorMessage: "",
  });
  const [lastname, setLastName] = useState<InputDetailField>({
    value: "",
    errorMessage: "",
  });
  const [email, setEmail] = useState<InputDetailField>({
    value: "",
    errorMessage: "",
  });
  const [organization, setOrganization] = useState<InputDetailField>({
    value: "",
    errorMessage: "",
  });
  const [foodRestriction, setFoodRestriction] = useState("");
  const [guest, setGuest] = useState(false);
  const [autoCheckin, setAutoCheckin] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { validateName, validateEmail, validateOrganization } =
    useValidateForm();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (firstname.value && lastname.value) {
      const emailValue = `${firstname.value.toLowerCase()}.${lastname.value.toLowerCase()}@exxonmobil.com`;
      setEmail({
        value: emailValue,
        errorMessage: "",
      });
    }
  }, [firstname, lastname]);

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const errorMessage = validateName(value);

    setFirstName({
      value: value.charAt(0).toUpperCase() + value.slice(1),
      errorMessage,
    });
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const errorMessage = validateName(value);

    setLastName({
      value: value.charAt(0).toUpperCase() + value.slice(1),
      errorMessage,
    });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const errorMessage = validateEmail(value);

    setEmail({
      value,
      errorMessage,
    });
  };

  const handleOrganizationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const errorMessage = validateOrganization(value);

    setOrganization({
      value,
      errorMessage,
    });
  };

  const handleSubmit = () => {
    if (!isFormInvalid()) {
      createEmployee();
    } else {
      setFirstName({
        value: firstname.value,
        errorMessage: validateName(firstname.value),
      });
      setLastName({
        value: lastname.value,
        errorMessage: validateName(lastname.value),
      });
      setEmail({
        value: email.value,
        errorMessage: validateEmail(email.value),
      });
      setOrganization({
        value: organization.value,
        errorMessage: validateOrganization(organization.value),
      });
    }
  };

  const createEmployee = async () => {
    setLoading(false);
    await createAttendee({
      firstname: firstname.value,
      lastname: lastname.value,
      email: email.value,
      organization: organization.value,
      foodRestriction,
      guest: guest,
    });
    if (autoCheckin) {
      await register({ email: email.value });
    }
    setLoading(true);
  };

  const isFormInvalid = () => {
    return (
      !firstname.value ||
      !lastname.value ||
      !email.value ||
      !organization.value ||
      !!firstname.errorMessage ||
      !!lastname.errorMessage ||
      !!email.errorMessage ||
      !!organization.errorMessage
    );
  };

  return (
    <>
      <div className="w-screen max-w-xl bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          New Attendance
        </h1>
        <label
          htmlFor="badge-id"
          className="block text-sm font-normal text-gray-700 mr-2"
        >
          Badge ID:
        </label>
        <input
          id="badge-id"
          type="text"
          className={`px-3 py-1 mb-3 w-full border border-gray-300 rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
          placeholder="Badge Id"
          value={badgeId}
          onChange={(event) => setBadgeId(event.target.value)}
        />

        <div className="mb-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-normal text-gray-700 mr-2"
          >
            <abbr className="text-red-500 mr-1">*</abbr>First Name:
          </label>
          <input
            id="first-name"
            type="text"
            className={`px-3 py-1 w-full border ${
              firstname.errorMessage
                ? "border-red-300 border-2"
                : "border-gray-300"
            } rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
            placeholder="First Name"
            required
            value={firstname.value}
            onChange={handleFirstNameChange}
          />
          {firstname.errorMessage && (
            <label className="block text-xs font-normal text-red-400">
              {firstname.errorMessage}
            </label>
          )}
        </div>
        <div className="mb-3">
          <label
            htmlFor="last-name"
            className="block text-sm font-normal text-gray-700 mr-2"
          >
            <abbr className="text-red-500 mr-1">*</abbr>Last Name:
          </label>
          <input
            id="last-name"
            type="text"
            className={`px-3 py-1 w-full border ${
              lastname.errorMessage
                ? "border-red-300 border-2"
                : "border-gray-300"
            } rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
            placeholder="Last Name"
            required
            value={lastname.value}
            onChange={handleLastNameChange}
          />
          {lastname.errorMessage && (
            <label className="block text-xs font-normal text-red-400">
              {lastname.errorMessage}
            </label>
          )}
        </div>

        <div className="mb-3">
          <label
            htmlFor="email"
            className="block text-sm font-normal text-gray-700 mr-2"
          >
            <abbr className="text-red-500 mr-1">*</abbr>Email:
          </label>
          <input
            id="email"
            type="text"
            className={`px-3 py-1 w-full border ${
              email.errorMessage ? "border-red-300 border-2" : "border-gray-300"
            } rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
            placeholder="Email"
            required
            value={email.value}
            onChange={handleEmailChange}
          />
          {email.errorMessage && (
            <label className="block text-xs font-normal text-red-400">
              {email.errorMessage}
            </label>
          )}
        </div>

        <div className="mb-3">
          <label
            htmlFor="organization"
            className="block text-sm font-normal text-gray-700 mr-2"
          >
            <abbr className="text-red-500 mr-1">*</abbr>Organization/Escort:
          </label>
          <input
            id="organization"
            type="text"
            className={`px-3 py-1 w-full border ${
              organization.errorMessage
                ? "border-red-300 border-2"
                : "border-gray-300"
            } rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
            placeholder="Organization or Escort"
            required
            value={organization.value}
            onChange={handleOrganizationChange}
          />
          {organization.errorMessage && (
            <label className="block text-xs font-normal text-red-400">
              {organization.errorMessage}
            </label>
          )}
        </div>
        <label
          htmlFor="food-restriction"
          className="block text-sm font-normal text-gray-700 mr-2"
        >
          Food Restriction
        </label>
        <input
          id="food-restriction"
          type="text"
          className={`px-3 py-1 mb-3 w-full border border-gray-300 rounded-md shadow-sm sm:text-base focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none focus:shadow-outline focus:border-2`}
          placeholder="Food Restriction"
          required
          value={foodRestriction}
          onChange={(event) => setFoodRestriction(event.target.value)}
        />
        <label
          htmlFor="food-restriction"
          className="block text-sm font-normal text-gray-700 mr-2 mb-1"
        >
          Guest
        </label>
        <div className="mb-2">
          <input
            type="radio"
            id="lucky-draw-eligible-yes"
            name="lucky-draw-eligible"
            value="yes"
            className="w-4 h-4"
            checked={guest}
            onChange={() => setGuest(true)}
          />
          <label
            htmlFor="lucky-draw-eligible-yes"
            className="text-gray-700 ml-1 text-base"
          >
            Yes
          </label>

          <input
            type="radio"
            id="lucky-draw-eligible-no"
            name="lucky-draw-eligible"
            value="no"
            className="ml-10 w-4 h-4"
            checked={!guest}
            onChange={() => setGuest(false)}
          />
          <label
            htmlFor="lucky-draw-eligible-no"
            className="text-gray-700 ml-1 text-base"
          >
            No
          </label>
        </div>
        <div className="mb-3 flex justify-center content-center">
          <input
            type="checkbox"
            id="auto-checkin"
            className="w-5 h-5"
            checked={autoCheckin}
            onChange={(event) => setAutoCheckin(event.target.checked)}
          />
          <label htmlFor="auto-checkin" className="text-gray-600 ml-2 text-sm">
            Auto Check-in
          </label>
        </div>
        <button
          type="submit"
          className={`${
            isFormInvalid()
              ? "bg-neutral-300 hover:bg-neutral-200"
              : "bg-customRed-500 hover:bg-customRed-600"
          }  text-white w-full py-2 rounded-md mb-3`}
          onClick={() => setShowConfirmModal(true)}
          disabled={isFormInvalid()}
        >
          Create
        </button>
        <div className="flex justify-center">
          <button
            className="text-red-500 hover:text-red-600"
            onClick={() => setShowCancelModal(true)}
          >
            Cancel
          </button>
        </div>
      </div>
      <ConfirmModal
        showModal={showConfirmModal}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={() => {
          setShowConfirmModal(false);
          handleSubmit();
          navigate("/admin/attendance-management");
        }}
        variant="confirm"
        rejectMessage="No"
        confirmMessage="Yes"
      >
        <p className="mb-5">Are you sure you want to create new user?</p>
        <div className="p-4 bg-gray-100 rounded-lg shadow-inner text-left">
          <h2 className="text-2xl font-bold text-gray-800">Employee Details</h2>
          <p>
            <strong>Name:</strong> {firstname.value + " " + lastname.value}
          </p>
          <p>
            <strong>Email:</strong> {email.value}
          </p>
          <p>
            <strong>Organization or Escort:</strong> {organization.value}
          </p>
          <p>
            <strong>Food Restriction:</strong> {foodRestriction.trim() || "-"}
          </p>
          <p>
            <strong>Guest:</strong> {guest ? "Yes" : "No"}
          </p>
        </div>
      </ConfirmModal>
      <ConfirmModal
        showModal={showCancelModal}
        onCancel={() => {
          setShowCancelModal(false);
        }}
        onConfirm={() => {
          setShowCancelModal(false);
          navigate("/admin/attendance-management");
        }}
        variant="cancel"
        rejectMessage="No"
        confirmMessage="Yes"
      >
        Are you sure you want to cancel?
      </ConfirmModal>
    </>
  );
};

export default AttendanceManagementPage;
