import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "3427ed72-8db3-474b-9338-a2b23404ca7c",
    authority:
      "https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [
    "api://em-event-registration-api-service-nonprod/delegated-scope offline_access openid",
  ],
};
