import React from "react";
import { useNavigate } from "react-router-dom";

const UnavailablePage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/admin");
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-4">Unavailable</h1>
        <p className="text-lg mb-6">
          The page you are looking for might be unavailable, or you don't have
          enough permission to view it.
        </p>
        <button
          onClick={handleGoBack}
          className="px-4 py-2 bg-customRed-500 text-white rounded hover:bg-blue-700 transition duration-300"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default UnavailablePage;
