import { ArrowPathIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { registryService } from "../service";
import EmployeeCard from "../components/EmployeeCard";
import { useLoading } from "../store/loader";
import { AttendeeModel, EmployeeModel } from "../models/EmployeeModel";

const EventRegistrationPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeModel | null>(null);
  const [employeeList, setEmployeeList] = useState<AttendeeModel[]>([]);
  const [badgeId, setBadgeId] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [isError, setIsError] = useState(false);
  const { setLoading } = useLoading();
  const [registerCount, setRegisterCount] = useState(-1);
  const MINUTE_MS = 30000;

  const registerEmployee = async ({
    badgeId,
    email,
  }: {
    badgeId?: string;
    email?: string;
  }) => {
    try {
      setLoading(false);
      setIsError(false);
      const profile = await registryService.register({ badgeId, email });
      await getRegisterCount();
      setSelectedEmployee(profile);
    } catch (err) {
      console.error(err);
      setIsError(true);
    } finally {
      setLoading(true);
    }
  };

  const handleSubmitWithBadgeId = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      await registerEmployee({ badgeId });
      setBadgeId("");
    }
  };

  const handleSubmitWithSearchName = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const email = event.currentTarget.getAttribute("data-email") || "";
    setEmployeeList([]);
    setSearchTerm("");
    setShowDropDown(false);
    await registerEmployee({ email });
  };

  const handleSearchName = (fullname: string) => {
    try {
      setShowDropDown(true);
      setLoadingSearch(true);
      registryService.searchAttendee(fullname).then((employeeList) => {
        setEmployeeList(employeeList);
        setLoadingSearch(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchEmployeeName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (searchTerm) {
        handleSearchName(event.currentTarget.value);
      } else {
        setEmployeeList([]);
        setShowDropDown(false);
      }
    } else if (event.key === "Escape") {
      setEmployeeList([]);
      setShowDropDown(false);
    }
  };

  const getRegisterCount = async () => {
    const count = await registryService.getRegisterCount();
    setRegisterCount(count);
  };

  useEffect(() => {
    getRegisterCount();

    const interval = setInterval(() => {
      getRegisterCount();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center justify-center">
      <div className="w-screen max-w-lg bg-white shadow-lg rounded-lg p-8 space-y-8 backdrop-filter backdrop-blur-lg">
        <h1 className="text-4xl text-gray-900 mb-6 text-center">
          Event Registration
        </h1>
        <div className="flex m-0">
          <label className="block text-xl font-medium text-gray-700 m-0">
            Register count:{" "}
            {registerCount === -1 ? "Loading..." : registerCount}
          </label>
          <ArrowPathIcon
            className="pl-3 cursor-pointer h-8 w-8 text-customRed-300"
            onClick={() => {
              if (registerCount === -1) return;
              setRegisterCount(-1);
              getRegisterCount();
            }}
          ></ArrowPathIcon>
        </div>
        <div>
          <label
            htmlFor="badge-id"
            className="block text-xl font-medium text-gray-700"
          >
            Badge ID:
          </label>
          <input
            id="badge-id"
            type="text"
            className={`mt-2 block w-full px-4 py-3 border ${
              isError ? "border-red-500" : "border-gray-300"
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg`}
            placeholder="Scan your Badge"
            onChange={(e) => {
              setBadgeId(e.target.value);
            }}
            onKeyDown={(e) => {
              handleSubmitWithBadgeId(e);
            }}
            value={badgeId}
          />
          {isError && <p className="text-red-500 mt-2">BadgeID not found</p>}
        </div>
        <div>
          <label
            htmlFor="employee-name"
            className="block text-xl font-medium text-gray-700"
          >
            Employee Name:
          </label>
          <div className="relative">
            <div className="mt-2 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                id="employee-name"
                type="text"
                className="block w-full pl-12 px-4 py-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg"
                placeholder="Search by Name"
                value={searchTerm}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchTerm(value);
                  if (!value) {
                    setEmployeeList([]);
                    setShowDropDown(false);
                  }
                }}
                onKeyDown={handleSearchEmployeeName}
              />
            </div>
            {showDropDown && (
              <div className="mt-2 bg-white shadow-md rounded-md z-10 absolute w-full overflow-auto max-h-64">
                {loadingSearch ? (
                  <div className="flex justify-center items-center p-3">
                    <div className="w-6 h-6 border-4 border-customRed-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                  </div>
                ) : employeeList.length ? (
                  employeeList.map(({ firstname, lastname, email }) => (
                    <div
                      key={`${firstname} ${lastname}`}
                      className="flex justify-between items-center p-3 hover:bg-gray-100"
                    >
                      <span>{`${firstname} ${lastname}`}</span>
                      <button
                        data-email={email}
                        onClick={handleSubmitWithSearchName}
                        className="ml-4 bg-customRed-500 text-white px-3 py-1 rounded-md"
                      >
                        Check-in
                      </button>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center items-center p-3 text-red-500 mt-2">
                    Employee Not Found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {selectedEmployee && <EmployeeCard employee={selectedEmployee} />}
      </div>
    </div>
  );
};

export default EventRegistrationPage;
