function useValidateForm() {
  const validateName = (name: string): string => {
    if (name.trim() === "") {
      return "Name is required";
    } else if (/\d/.test(name)) {
      return "Name should not contain numbers";
    }
    return '';
  }

  const validatePrize = (name: string): string => {
    if (name.trim() === "") {
      return "Prize is required";
    }
    return '';
  }

  const validateEmail = (email: string): string => {
    if (email.trim() === "") {
      return "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      return "Invalid email format";
    }
    return '';
  }

  const validateOrganization = (name: string): string => {
    if (name.trim() === "") {
      return "Organization is required";
    }
    return '';
  }

  const validateQuantity = (quantity: string | number): string => {
    if (typeof quantity === "string") {
      if (quantity.trim() === "") {
        return "Quantity is required";
      } else if (!/^\d+$/.test(quantity)) {
        return "Quantity should be a number";
      } else if (parseInt(quantity) <= 0) {
        return "Quantity should be greater than 0";
      }
    } else if (typeof quantity === "number") {
      if (quantity <= 0) {
        return "Quantity should be greater than 0";
      }
    }
    return '';
  }
  

  return {
    validateName,
    validateEmail,
    validateOrganization,
    validatePrize,
    validateQuantity
  };
}

export default useValidateForm;