// RootLayout.js
import { Outlet, useNavigation } from "react-router-dom";
import Sidebar from "./SideBar";
import { useEffect } from "react";
import { useLoading } from "../store/loader";

function RootLayout() {
  const navigation = useNavigation();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (navigation.state === "loading") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [navigation]);
  
  return (
    <div>
      <Sidebar />
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

export default RootLayout;
