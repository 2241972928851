import React, { FunctionComponent } from "react";

interface IProps {
  isLoaded: boolean;
}

const LoaderScreen: FunctionComponent<IProps> = (props) => {
  if (props.isLoaded) {
    return null;
  }
  return (
    <div className="fixed inset-0 bg-black opacity-75 z-[99] pointer-events-auto flex items-center justify-center">
      <div className="w-12 h-12 border-4 border-customRed-500 border-t-transparent border-solid rounded-full animate-spin"></div>
    </div>
  );
};

export default LoaderScreen;
