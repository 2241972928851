import { useMsal } from "@azure/msal-react";
import React, { createContext, useContext, useEffect, useState } from "react";

interface UserRoleContextProps {
  userRoles: string[];
  loading: boolean;
}

const UserRoleContext = createContext<UserRoleContextProps | undefined>(
  undefined
);

export const UserRoleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { instance, accounts } = useMsal();
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);

  const getUserRole = async () => {
    try {
      const account = accounts[0];
      const response = await instance.acquireTokenSilent({
        scopes: [
          "api://em-event-registration-api-service-nonprod/delegated-scope offline_access openid",
        ],
        account,
      });
      const { roles } = JSON.parse(atob(response.accessToken.split(".")[1]));
      setUserRoles(roles);
    } catch (error) {
      console.error("Error acquiring token silently:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserRole();
  }, []);

  return (
    <UserRoleContext.Provider value={{ userRoles, loading }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (context === undefined) {
    throw new Error("useUserRole must be used within a UserRoleProvider");
  }
  return context;
};
