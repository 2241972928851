import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PrizeModel } from "../../../models/PrizeModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { prizeService } from "../../../service";
import { useLoading } from "../../../store/loader";

const ViewPrize = () => {
  const { id } = useParams();
  const location = useLocation();
  const { data: locationStates } = location.state || {};
  const navigate = useNavigate();
  const allPrizes: PrizeModel[] = location.state.data || [];
  const [showWinners, setShowWinners] = useState(false);
  const [currentWinnerIndex, setCurrentWinnerIndex] = useState(0);
  const [showRedrawButton, setShowRedrawButton] = useState(false);
  const [showAllWinner, setShowAllWinner] = useState(false);
  const { setLoading } = useLoading();
  const [hide, setHide] = useState(false);
  const [data] = useState(
    id ? allPrizes.find((prize) => prize.id === parseInt(id)) : undefined
  );

  useEffect(() => {
    const handlePopState = () => {
      navigate("/admin/lucky-draw");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    const handleCLick = (event: any) => {
      if (event.key === "h") {
        setHide((prevShow) => !prevShow);
      }
    };

    window.addEventListener("keydown", handleCLick);

    return () => {
      window.removeEventListener("keydown", handleCLick);
    };
  }, []);

  const handleShowWinners = () => {
    setShowWinners(!showWinners);
  };

  const handleRedraw = async (index: number) => {
    try {
      setLoading(false);
      if (data && id) {
        const newWinner = await prizeService.reDrawPrize(
          data.id,
          data.winners[index].email
        );
        data.winners[index] = newWinner.luckyPeoples[0];
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(true);
    }
  };

  const handleNextWinner = () => {
    if (data && currentWinnerIndex < data.winners.length - 1) {
      setCurrentWinnerIndex(currentWinnerIndex + 1);
    }
  };

  const handlePreviousWinner = () => {
    if (currentWinnerIndex > 0) {
      setCurrentWinnerIndex(currentWinnerIndex - 1);
    }
  };

  const { name: prize, winners } = locationStates.find(
    (item: PrizeModel) => `${item.id}` === id
  );
  const countWinner = winners.length > 1 ? "s" : "";

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <button
        className="absolute top-4 left-4 bg-transparent text-black px-4 py-2 rounded font-bold"
        onClick={() => navigate("/admin/lucky-draw")}
      >
        <FontAwesomeIcon
          className={`select-none rotate-180 text-white ${hide ? "hidden" : ""}`}
          icon={faPlay}
          aria-label="Edit"
        />
      </button>
      <img
        className="select-none max-w-96 absolute top-4"
        src="../../../em-logo.png"
        alt="logo"
      />
      <div
        className={`w-screen flex flex-col items-center space-y-2 ${
          showAllWinner ? "mt-36" : ""
        }`}
      >
        {showWinners && (
          <div className="w-screen max-w-5xl max-h-[1280px] bg-customRed-600 bg-opacity-60 shadow-lg rounded-lg p-5 space-y-3">
            <h1 className="select-none text-5xl text-center text-white mb-5">
              {prize} Winner{countWinner}
            </h1>
            <hr className="border-t-2 border-gray-300 my-2 w-full" />

            {showAllWinner ? (
              <div className="flex flex-col items-center space-y-2 max-h-128 overflow-y-auto">
                {data?.winners.length ? (
                  data.winners.map((winner, index) => (
                    <div
                      key={index}
                      className="relative bg-gray-500 bg-opacity-40 p-2 rounded-lg shadow-md w-full flex justify-between items-center py-3"
                    >
                      <div className="select-none text-5xl text-white text-center w-full tracking-wide ">
                        {winner.firstname} {winner.lastname}
                      </div>
                      {data.onStage && showRedrawButton && (
                        <FontAwesomeIcon
                          className="ml-3 bg-red-600 text-white px-4 py-2 rounded cursor-pointer absolute right-4"
                          onClick={() => handleRedraw(index)}
                          icon={faRefresh}
                          aria-label="Edit"
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No winners found</p>
                )}
              </div>
            ) : data?.winners.length ? (
              <div className="relative bg-gray-500 bg-opacity-40 p-2 rounded-lg shadow-md w-full flex justify-center items-center">
                <div className="select-none text-5xl text-white text-center tracking-wide w-full">
                  {data.winners[currentWinnerIndex].firstname}{" "}
                  {data.winners[currentWinnerIndex].lastname}
                </div>
                {data.onStage && showRedrawButton && (
                  <FontAwesomeIcon
                    className="absolute right-4 bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => handleRedraw(currentWinnerIndex)}
                    icon={faRefresh}
                    aria-label="Edit"
                  />
                )}
              </div>
            ) : (
              <p className="text-gray-500">No winners found</p>
            )}
          </div>
        )}
      </div>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex flex-col items-center space-y-2">
        <button
          className={`${hide ? "hidden" : ""} select-none bg-transparent text-white font-bold px-4 py-2 rounded`}
          onClick={handleShowWinners}
        >
          {showWinners ? "Hide" : "Show"}
        </button>
        {!showAllWinner && (
          <div className="flex items-center space-x-2">
            {data && (
              <>
                <button
                  className={`${hide ? "hidden" : ""} select-none bg-transparent text-white font-bold px-4 py-2 rounded ${
                    currentWinnerIndex === 0 ? "invisible" : ""
                  }`}
                  onClick={handlePreviousWinner}
                  disabled={currentWinnerIndex === 0}
                >
                  Prev
                </button>
                <div className={`${hide ? "hidden" : ""} select-none bg-transparent text-white font-bold px-4 py-2 rounded`}>
                  {currentWinnerIndex + 1}
                </div>
                <button
                  className={`${hide ? "hidden" : ""} select-none bg-transparent text-white font-bold px-4 py-2 rounded ${
                    currentWinnerIndex + 1 === data.winners.length
                      ? "invisible"
                      : ""
                  }`}
                  onClick={handleNextWinner}
                  disabled={currentWinnerIndex + 1 === data.winners.length}
                >
                  Next
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {data?.onStage && (
        <button
          className={`${hide ? "hidden" : ""} select-none absolute bottom-4 left-4 bg-transparent text-white font-bold px-4 py-2 rounded`}
          onClick={() => setShowRedrawButton(!showRedrawButton)}
        >
          {showRedrawButton ? "Hide Redraw" : "Show Redraw"}
        </button>
      )}
      <button
        className={`${hide ? "hidden" : ""} select-none absolute bottom-4 right-4 bg-transparent text-white font-bold px-4 py-2 rounded`}
        onClick={() => setShowAllWinner(!showAllWinner)}
      >
        {showAllWinner ? "Hide all" : "Show all"}
      </button>
    </div>
  );
};

export default ViewPrize;
