import { useMsal } from "@azure/msal-react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUserRole } from "../contexts/UserRoleContext";

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const { instance } = useMsal();
  const page = window.location.pathname;
  const { userRoles } = useUserRole();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (page.includes("winners")) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [page]);

  const hasRole = (role: string) => userRoles.includes(role);

  return (
    <>
      <FontAwesomeIcon
        className={`fixed top-4 right-4 z-50 p-2 text-4xl text-white ${
          hide ? "hidden" : ""
        }`}
        onClick={toggleSidebar}
        icon={faBars}
      />

      <div
        className={`fixed top-0 right-0 h-full w-64 bg-gray-700 bg-opacity-50 text-white transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-40`}
      >
        <div className="p-4 h-screen flex flex-col">
          <h2 className="text-xl font-bold top-2 p-3">EMIT YEAR END</h2>
          <ul className="mt-4 p-3 flex flex-col gap-4 grow">
            {hasRole("write:em-event-register") && (
              <li>
                <Link
                  to="/admin/register"
                  className="block p-2 rounded hover:bg-gray-700"
                >
                  Register Menu
                </Link>
              </li>
            )}
            {hasRole("write:em-event-lucky-draw") && (
              <li>
                <Link
                  to="/admin/lucky-draw"
                  className="block p-2 rounded hover:bg-gray-700"
                >
                  Lucky Draw
                </Link>
              </li>
            )}
            {hasRole("write:em-event-register") && (
              <li>
                <Link
                  to="/admin/attendance-management"
                  className="block p-2 rounded hover:bg-gray-700"
                >
                  Attendance Management
                </Link>
              </li>
            )}
            <li className="grow"></li>
            <li
              className="block p-2 rounded hover:bg-gray-700 cursor-pointer"
              onClick={() => instance.logout()}
            >
              Sign out
            </li>
          </ul>
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 opacity-0 z-30"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
