/**
 * References:
 * - https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/react/modals/regular
 * - https://flowbite.com/docs/components/modal/#pop-up-modal
 *  */
import React, { ReactNode } from "react";

interface ConfirmModalProps {
  showModal?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  title?: string;
  description?: string;
  children?: ReactNode;
  variant?: "confirm" | "cancel" | "draw";
  confirmMessage?: string;
  rejectMessage?: string;
  hideRejectButton?: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  showModal = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = "Confirmation",
  children = "Are you sure you want to proceed?",
  variant = "cancel",
  confirmMessage = "Confirm",
  rejectMessage = "Cancel",
  hideRejectButton = false,
}) => {
  const acceptStyleButton = {
    confirm: "bg-emerald-500 hover:bg-emerald-400",
    cancel: "bg-red-500 hover:bg-red-400",
    draw: "bg-emerald-500 hover:bg-emerald-400"
  };

  const rejectStyleButton = {
    confirm: "bg-red-500 hover:bg-red-400",
    cancel: "hover:bg-neutral-300 bg-neutral-400",
    draw: "hover:bg-neutral-300 bg-neutral-400"
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50">
            <div className="relative w-auto my-6 mx-auto max-w-lg min-w-96">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white p-4">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={onCancel}
                    className="text-gray-400 hover:text-gray-300 w-3 h-3"
                    data-modal-hide="popup-modal"
                  >
                    <svg
                      className="w-3 h-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto text-base text-center mb-4">
                  <svg
                    className="mx-auto mb-2 w-14 h-14 text-neutral-200"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  {children}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center rounded-b space-x-4">
                  <button
                    className={`${rejectStyleButton[variant]} px-6 py-2 text-sm text-white rounded`}
                    type="button"
                    onClick={onCancel}
                  >
                    {rejectMessage}
                  </button>
                  <button
                    className={`${acceptStyleButton[variant]} text-white text-sm px-6 py-2 rounded`}
                    type="button"
                    onClick={onConfirm}
                  >
                    {confirmMessage}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ConfirmModal;
